import "./App.css";
const LeftSide = () => {
  return (
    <div>
      <h1 className="Title">My Diary</h1>
    </div>
  );
};

export default LeftSide;
